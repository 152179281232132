import React from "react"

import BrandPreview from "../components/generic-preview/brand-preview/brand-preview"
import OverviewGrid from "../components/overview-grid/overview-grid"
import PageHeader from "../components/page-header/page-header"
import SEO from "../components/seo"
import useBrands from "../hooks/useBrands"
import { useTranslation } from "../i18n/translate"

const BrandsPage = ({ location }) => {
  const { t } = useTranslation()
  const brands = useBrands()

  return (
    <>
      <SEO
        pathName={location.pathname}
        titleTranslatedString={t("BRANDS-META_TITLE")}
        descriptionTranslatedString={t("BRANDS-META_DESCRIPTION")}
      />
      <PageHeader
        levelThreeTitle={t("BRANDS-META_TITLE")}
        levelTwoTitle={t("BRANDS-META_TITLE")}
      />
      <div className="_fp-global-container">
        <div className="_fp-col-12">
          <OverviewGrid
            topRowChildren={
              <p className={"_fp-text _fp-text--columns _fp-text--drop-caps"}>
                {t("BRANDS-PAGE_DESCRIPTION")}
              </p>
            }
          >
            {brands
              .filter((brand) => !brand.hidden)
              .sort((a, b) => {
                return t(a.name.key).localeCompare(t(b.name.key))
              })
              .map((brand) => {
                return <BrandPreview brand={brand} key={`key_${brand.id}`} />
              })}
          </OverviewGrid>
        </div>
      </div>
    </>
  )
}

export default BrandsPage
